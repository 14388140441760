/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  /* 1rem = 10px */
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
  line-height: 2rem;
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  background-color: #0B256E;
}
@media (max-width: 1199.98px) {
  body {
    overflow: hidden;
  }
}

.container {
  margin: 0 auto;
  max-width: 144rem;
  padding: 0 2rem;
}

a:focus, button:focus, input:focus, select:focus {
  outline: none;
}

.page-content {
  margin-top: 12rem;
}
.page-content p {
  margin-bottom: 1.6rem;
}

.mdl-paw-des {
  display: flex;
  justify-content: center;
  overflow: hidden;
}
.mdl-paw-des img {
  display: block;
  width: auto;
  height: 100vh;
  border: 0;
  object-fit: cover;
}
@media (max-width: 1440px) {
  .mdl-paw-des img {
    width: 100%;
    height: auto;
    height: 100vh;
  }
}

.mdl-paw {
  background: url("../imgs/bg.jpg") #0B256E no-repeat center bottom fixed;
  background-size: cover;
  height: 100vh;
  overflow: hidden;
  position: relative;
}
@media (max-width: 1199.98px) {
  .mdl-paw {
    background-image: url("../imgs/bg-tab.jpg");
  }
}
@media (max-width: 575.98px) {
  .mdl-paw {
    background-image: url("../imgs/bg-mob.jpg");
  }
}
@media only screen and (max-width: 1199.98px) and (orientation: landscape) {
  .mdl-paw {
    width: 100vh;
    height: 100vw;
  }
}
.mdl-paw video {
  height: 100vh;
  z-index: 8;
  position: relative;
}
@media only screen and (max-width: 1199.98px) and (orientation: landscape) {
  .mdl-paw video {
    height: 100vw;
  }
}

#js-welcome_bt {
  position: absolute;
  z-index: 0;
  visibility: hidden;
}

.content {
  padding: 2.8rem 3.6rem;
  font-size: 2.4rem;
  line-height: 2.8rem;
  text-align: left;
  color: #fff;
  width: 100%;
  box-sizing: border-box;
}
@media (max-width: 575.98px) {
  .content {
    padding: 2rem;
    font-size: 1.8rem;
    line-height: 2.2rem;
  }
}
.content img {
  max-width: 27.5rem;
  margin-bottom: 1rem;
}
.content p {
  margin-bottom: 0.6rem;
  max-width: 25rem;
}
@media (max-width: 575.98px) {
  .content p {
    margin-bottom: 1rem;
  }
}

.btn {
  max-width: 17.6rem;
  width: 100%;
  height: 4.1rem;
  border-radius: 2.3rem;
  background: #FAC515;
  font-family: "Nunito", sans-serif;
  font-weight: normal;
  font-size: 1.8rem;
  line-height: 2rem;
  text-align: center;
  color: #151515;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 0;
  margin-top: 1rem;
  min-height: 4.1rem;
}

.bubble {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTc4LjIzOSIgaGVpZ2h0PSIxNDUuODIiIHZpZXdCb3g9IjAgMCAxNzguMjM5IDE0NS44MiI+DQogIDxkZWZzPg0KICAgIDxjbGlwUGF0aCBpZD0iY2xpcC1wYXRoIj4NCiAgICAgIDxyZWN0IGlkPSJSZWN0w6FuZ3Vsb18xODUiIGRhdGEtbmFtZT0iUmVjdMOhbmd1bG8gMTg1IiB3aWR0aD0iMTc4LjIzOSIgaGVpZ2h0PSIxNDUuODIiIGZpbGw9Im5vbmUiLz4NCiAgICA8L2NsaXBQYXRoPg0KICA8L2RlZnM+DQogIDxnIGlkPSJHcnVwb18xMDUiIGRhdGEtbmFtZT0iR3J1cG8gMTA1IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIDApIj4NCiAgICA8ZyBpZD0iR3J1cG9fMTA0IiBkYXRhLW5hbWU9IkdydXBvIDEwNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCAwKSIgY2xpcC1wYXRoPSJ1cmwoI2NsaXAtcGF0aCkiPg0KICAgICAgPHBhdGggaWQ9IlRyYXphZG9fNDk0IiBkYXRhLW5hbWU9IlRyYXphZG8gNDk0IiBkPSJNMTI0LjkxMSwxMy4zMzdjNTEuNTM2LDI3LjMzMiw4Mi4wMTcsOTQuODU0LDE0LDEyNEM2OC42ODksMTY3LjQ0NCwzLjg3LDExMS40MTkuMTYyLDYzLjA1OS0xLjksMzYuMjMyLDE1Ljg2NiwxNC41LDM4LjkzMSw1LjUyMWMyMi44MDgtOC44MjUsNTYuODktNy42NjUsODUuOTgsNy44MTYiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgMCkiIGZpbGw9IiMwYzI1NmUiLz4NCiAgICA8L2c+DQogIDwvZz4NCjwvc3ZnPg0K");
  max-width: 18.8rem;
  height: 15.6rem;
  display: flex;
  padding: 1rem 1rem 1rem 3rem;
  box-sizing: border-box;
  font-size: 2rem;
  line-height: 2.2rem;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: 18.8rem;
}
@media (max-width: 575.98px) {
  .bubble {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }
}

.js-content {
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  flex-flow: column;
  max-width: 42rem;
  margin: 0 auto;
}
.js-content .btn {
  align-self: center;
  margin-bottom: 5rem;
}
.js-content .logo {
  padding-top: 3.8rem;
  max-width: 14rem;
  align-self: center;
}
@media (max-width: 376.98px) {
  .js-content .logo {
    padding-top: 2.5rem;
  }
}
.js-content p.sm {
  font-size: 1.4rem;
  line-height: 1.6rem;
  text-align: center;
  max-width: 100%;
  color: #fff;
  padding: 0 3.6rem;
}
@media (max-width: 575.98px) {
  .js-content p.sm {
    padding: 0 2rem;
  }
}

.js-contest {
  height: 100vh;
  background: url("../imgs/bg-blur.jpg") #0B256E no-repeat center bottom fixed;
  background-size: cover;
}
@media (max-width: 1199.98px) {
  .js-contest {
    background-image: url("../imgs/bg-blur-tab.jpg");
  }
}
@media (max-width: 575.98px) {
  .js-contest {
    background-image: url("../imgs/bg-blur-mob.jpg");
  }
}
@media only screen and (max-width: 1199.98px) and (orientation: landscape) {
  .js-contest {
    width: 100vh;
    height: 100vw;
  }
}

.form-bx {
  width: 100%;
  margin: 0 auto;
  padding-top: 0;
  padding-bottom: 4.6rem;
  height: 100%;
  background: rgba(11, 37, 110, 0.63);
}
.form-bx form {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0 2rem;
  height: calc(100vh - 24rem);
  overflow-y: auto;
  padding-bottom: 2rem;
}
@media only screen and (max-width: 1199.98px) and (orientation: landscape) {
  .form-bx form {
    height: calc(100vw - 24rem);
  }
}

.form-col {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.form-group {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 26.3rem;
  width: 100%;
}

.form-label {
  font-family: "Nunito", sans-serif;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 1.3rem;
  text-align: left;
  color: #fff;
  margin-bottom: 0.7rem;
}

.form-control {
  font-family: "Nunito", sans-serif;
  background-color: rgba(255, 255, 255, 0.56);
  min-height: 4.2rem;
  border-radius: 3.1rem;
  width: 100%;
  margin-bottom: 1.4rem;
  padding: 0.5rem 1.7rem;
  font-size: 1.4rem;
  line-height: 1.2rem;
  color: #fff;
  border: 0;
  box-sizing: border-box;
}

input[type=date] {
  min-width: calc(100% - 13px);
  -webkit-appearance: none;
  text-align: left;
}

.form-control::placeholder {
  color: #fff;
  opacity: 0.77;
  font-size: 1.4rem;
  line-height: 1.2rem;
}

input[type=submit] {
  max-width: 17.6rem;
  width: 100%;
  height: 4.1rem;
  border-radius: 2.3rem;
  background: #FAC515;
  font-family: "Nunito", sans-serif;
  font-weight: normal;
  font-size: 1.8rem;
  line-height: 2rem;
  text-align: center;
  color: #151515;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 0;
  margin-top: 1rem;
  min-height: 4.1rem;
}

select {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDE2IDE0Ij4NCiAgPHBhdGggaWQ9IlBvbMOtZ29ub18xIiBkYXRhLW5hbWU9IlBvbMOtZ29ubyAxIiBkPSJNOCwwbDgsMTRIMFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE2IDE0KSByb3RhdGUoMTgwKSIgZmlsbD0iI2ZmZiIvPg0KPC9zdmc+DQo=");
  background-repeat: no-repeat;
  background-position: right 1.8rem center;
  background-size: 1.6rem 1.4rem;
}

select {
  appearance: none;
}

select option {
  color: #000;
  background-color: rgba(255, 255, 255, 0.56);
}

input[type=date]::-webkit-inner-spin-button,
input[type=date]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
  appearance: none;
}

.form-header {
  font-family: "Nunito", sans-serif;
  font-size: 1.6rem;
  line-height: 1.8rem;
  font-weight: 700;
  text-align: center;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  flex-flow: column;
  padding: 3.8rem 2rem 3rem;
  box-sizing: border-box;
}
@media (max-width: 376.98px) {
  .form-header {
    padding: 2.5rem 2rem 2rem;
  }
}
.form-header p {
  margin-bottom: 1.5rem;
  max-width: 38rem;
  padding: 0 2rem;
}
.form-header img {
  margin-bottom: 2.5rem;
}

.form-footer {
  font-family: "Nunito", sans-serif;
  font-weight: normal;
  font-size: 1.3rem;
  line-height: 2rem;
  text-align: left;
  color: #fff;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
}

.form-footer a {
  color: #fff;
}

.check-group {
  align-self: flex-start;
}

.custom-checkbox {
  display: block;
  margin-bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  line-height: 2.6rem;
  text-align: left;
  color: #fff;
  margin-bottom: 0.9rem;
  position: relative;
}
.custom-checkbox input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  cursor: pointer;
  left: 0.7rem;
  position: absolute;
  top: 0.2rem;
  opacity: 0;
}
.custom-checkbox label {
  position: relative;
  cursor: pointer;
  font-size: 1.2rem;
  line-height: 2rem;
  text-align: left;
  color: #fff;
  font-family: "Nunito", sans-serif;
  padding-left: 2.5rem;
}
.custom-checkbox label:after {
  content: "";
  display: block;
  position: absolute;
  top: 0.2rem;
  left: 0.2rem;
  width: 1rem;
  height: 1rem;
  background-color: #fff;
  border-radius: 100%;
  border: 0.2rem solid #fff;
}
.custom-checkbox label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  box-shadow: none;
  padding: 0;
  display: inline-block;
  position: absolute;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 0.5rem;
  padding: 0;
  left: 0;
  top: 0;
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 100%;
  background: #452808;
  border: 0;
}
.custom-checkbox label a {
  color: #fff;
}
.custom-checkbox input:checked + label:after {
  background-color: #452808;
}
.custom-checkbox input:checked + label:before {
  border-color: #fff;
}

.alert {
  padding: 1rem 2rem;
  display: block;
  text-align: center;
  font-size: 1.2rem;
  line-height: 1.4rem;
  font-family: "Nunito", sans-serif;
  background-color: rgba(255, 255, 255, 0.56);
  border-radius: 5rem;
  margin-top: 1rem;
  color: #fff;
  box-sizing: content-box;
}

.js-fb {
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 9;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("../imgs/bg-fb.jpg") #0B256E no-repeat center bottom fixed;
  background-size: cover;
}
@media (max-width: 1199.98px) {
  .js-fb {
    background-image: url("../imgs/bg-fb-tab.jpg");
  }
}
@media (max-width: 575.98px) {
  .js-fb {
    background-image: url("../imgs/bg-fb-mob.jpg");
  }
}
.js-fb__bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  padding-top: 3.8rem;
  align-items: flex-start;
}
@media (max-width: 376.98px) {
  .js-fb__bg {
    padding-top: 2.5rem;
  }
}
.js-fb__bg img {
  max-width: 14rem;
}
.js-fb__it {
  width: 100%;
  font-size: 1.6rem;
  line-height: 1.8rem;
  text-align: center;
  color: #27180e;
  max-width: 28.7rem;
  border-radius: 0.7rem;
  background: rgba(250, 196, 22, 0.92);
  border: 0.1rem solid #fff;
  min-height: 22rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  padding: 1.2rem;
  box-sizing: border-box;
}
.js-fb__it h3 {
  font-weight: bold;
  font-size: 2.4rem;
  line-height: 1.8rem;
  text-align: center;
  color: #fff;
  margin-bottom: 1.7rem;
}
.js-fb__it strong {
  font-weight: 700;
}
.js-fb__it--ko {
  background: rgba(11, 37, 110, 0.86);
  border: 0.1rem solid #FAC515;
  color: #fff;
  padding: 1.2rem 2.6rem;
}