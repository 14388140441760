.mdl-paw-des {
  // background: url("../imgs/bg-full.jpg") no-repeat center bottom fixed;
  // background-size: cover;
  // background-color: black;
  display: flex;
  justify-content: center;
  overflow: hidden;

  img{
    display: block;
    width: auto;
    height: 100vh;
    border: 0;
    object-fit: cover;

    @media(max-width: 1440px){
      width: 100%;
      height: auto;
      height: 100vh;
    }
  }

}

.mdl-paw{
  background: url('../imgs/bg.jpg') $blue no-repeat center bottom fixed;
  background-size: cover;
  height: 100vh;
  overflow: hidden;
  // display: none;
  position: relative;

  @media(max-width: $xl){
    background-image: url('../imgs/bg-tab.jpg');
  }

  @media(max-width: $sm){
    background-image: url('../imgs/bg-mob.jpg');
  }

  @media only screen and (max-width: $xl) and (orientation: landscape) {
    width: 100vh;
    height: 100vw;
  }

  video{
    height: 100vh;
    z-index: 8;
    position: relative;

    @media only screen and (max-width: $xl) and (orientation: landscape) {
      height: 100vw;
    }
  }
}

#js-welcome_bt{
  position: absolute;
  z-index: 0;
  visibility: hidden;
}

.content{
  padding: 2.8rem 3.6rem;
  font-size: 2.4rem;
  line-height: 2.8rem;
  text-align: left;
  color: $white;
  width: 100%;
  box-sizing: border-box;

  @media(max-width: $sm){
    padding: 2rem;
    font-size: 1.8rem;
    line-height: 2.2rem;
  }

  img{
    max-width: 27.5rem;
    margin-bottom: 1rem;
  }

  p{
    margin-bottom: 0.6rem;
    max-width: 25rem;

    @media(max-width: $sm){
      margin-bottom: 1rem;
    }
  }
  
}

.btn{
  max-width: 17.6rem;
  width: 100%;
  height: 4.1rem;
  border-radius: 2.3rem;
  background: $yellow;
  font-family: $font_nunito;
  font-weight: normal;
  font-size: 1.8rem;
  line-height: 2rem;
  text-align: center;
  color: #151515;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 0;
  margin-top: 1rem;
  min-height: 4.1rem;
}

.bubble{
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTc4LjIzOSIgaGVpZ2h0PSIxNDUuODIiIHZpZXdCb3g9IjAgMCAxNzguMjM5IDE0NS44MiI+DQogIDxkZWZzPg0KICAgIDxjbGlwUGF0aCBpZD0iY2xpcC1wYXRoIj4NCiAgICAgIDxyZWN0IGlkPSJSZWN0w6FuZ3Vsb18xODUiIGRhdGEtbmFtZT0iUmVjdMOhbmd1bG8gMTg1IiB3aWR0aD0iMTc4LjIzOSIgaGVpZ2h0PSIxNDUuODIiIGZpbGw9Im5vbmUiLz4NCiAgICA8L2NsaXBQYXRoPg0KICA8L2RlZnM+DQogIDxnIGlkPSJHcnVwb18xMDUiIGRhdGEtbmFtZT0iR3J1cG8gMTA1IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIDApIj4NCiAgICA8ZyBpZD0iR3J1cG9fMTA0IiBkYXRhLW5hbWU9IkdydXBvIDEwNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCAwKSIgY2xpcC1wYXRoPSJ1cmwoI2NsaXAtcGF0aCkiPg0KICAgICAgPHBhdGggaWQ9IlRyYXphZG9fNDk0IiBkYXRhLW5hbWU9IlRyYXphZG8gNDk0IiBkPSJNMTI0LjkxMSwxMy4zMzdjNTEuNTM2LDI3LjMzMiw4Mi4wMTcsOTQuODU0LDE0LDEyNEM2OC42ODksMTY3LjQ0NCwzLjg3LDExMS40MTkuMTYyLDYzLjA1OS0xLjksMzYuMjMyLDE1Ljg2NiwxNC41LDM4LjkzMSw1LjUyMWMyMi44MDgtOC44MjUsNTYuODktNy42NjUsODUuOTgsNy44MTYiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgMCkiIGZpbGw9IiMwYzI1NmUiLz4NCiAgICA8L2c+DQogIDwvZz4NCjwvc3ZnPg0K");
  max-width: 18.8rem;
  height: 15.6rem;
  display: flex;
  padding: 1rem 1rem 1rem 3rem;
  box-sizing: border-box;
  font-size: 2rem;
  line-height: 2.2rem;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: 18.8rem;

  @media(max-width: $sm){
    font-size: 1.8rem;
    line-height: 2.2rem;
  }
}

.js-content{
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  flex-flow: column;
  max-width: 42rem;
  margin: 0 auto;

  .btn{
    align-self: center;
    margin-bottom: 5rem;
  }

  .logo{
    padding-top: 3.8rem;
    max-width: 14rem;
    align-self: center;

    @media(max-width: $xs){
      padding-top: 2.5rem;
    }
  }

  p{
    &.sm{
      font-size: 1.4rem;
      line-height: 1.6rem;
      text-align: center;
      max-width: 100%;
      color: $white;
      padding: 0 3.6rem;

      @media(max-width: $sm){
        padding: 0 2rem;
      }
    }
  }
  
}

.js-contest{
  height: 100vh;
  background: url('../imgs/bg-blur.jpg') $blue no-repeat center bottom fixed;
  background-size: cover;

  @media(max-width: $xl){
    background-image: url('../imgs/bg-blur-tab.jpg');
  }

  @media(max-width: $sm){
    background-image: url('../imgs/bg-blur-mob.jpg');
  }

  @media only screen and (max-width: $xl) and (orientation: landscape) {
    width: 100vh;
    height: 100vw;
  }
}

.form-bx{
  width: 100%;
  // max-width: 44rem;
  margin: 0 auto;
  // background-color: #27180E;
  padding-top: 0;
  padding-bottom: 4.6rem;
  height: 100%;
  // background-color: rgba(39, 24, 14,0.81);
  background: rgba(11, 37, 110, .63);

  form{
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0 2rem;
    height: calc(100vh - 24rem);
    overflow-y: auto;
    padding-bottom: 2rem;

    @media only screen and (max-width: $xl) and (orientation: landscape) {
      height: calc(100vw - 24rem);
    }

  }
}

.form-col{
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.form-group {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 26.3rem;
  width: 100%;
}

.form-label{
  font-family: $font_nunito;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 1.3rem;
  text-align: left;
  color: $white;
  margin-bottom: 0.7rem;
}

.form-control {
  font-family: $font_nunito;
  background-color: rgba(255,255,255,0.56);
  min-height: 4.2rem;
  border-radius: 3.1rem;
  width: 100%;
  margin-bottom: 1.4rem;
  padding: .5rem 1.7rem;
  font-size: 1.4rem;
  line-height: 1.2rem;
  color: $white;
  border: 0;
  box-sizing: border-box;
}

input[type="date"]{
  min-width: calc(100% - 13px);
  -webkit-appearance: none;
  text-align: left;
}

.form-control::placeholder{
  color: $white;
  opacity: 0.77;
  font-size: 1.4rem;
  line-height: 1.2rem;
}

input[type="submit"]{
  max-width: 17.6rem;
  width: 100%;
  height: 4.1rem;
  border-radius: 2.3rem;
  background: $yellow;
  font-family: $font_nunito;
  font-weight: normal;
  font-size: 1.8rem;
  line-height: 2rem;
  text-align: center;
  color: #151515;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 0;
  margin-top: 1rem;
  min-height: 4.1rem;
}

select{
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDE2IDE0Ij4NCiAgPHBhdGggaWQ9IlBvbMOtZ29ub18xIiBkYXRhLW5hbWU9IlBvbMOtZ29ubyAxIiBkPSJNOCwwbDgsMTRIMFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE2IDE0KSByb3RhdGUoMTgwKSIgZmlsbD0iI2ZmZiIvPg0KPC9zdmc+DQo=');
  background-repeat: no-repeat;
  background-position: right 1.8rem center;
  background-size: 1.6rem 1.4rem;
}

select {
	appearance: none;
}

select option{
  color: #000;
  background-color: rgba(255,255,255,0.56);
}


input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
	  appearance: none;

}


.form-header {
  font-family: $font_nunito;
  font-size: 1.6rem;
  line-height: 1.8rem;
  font-weight: 700;
  text-align: center;
  color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  flex-flow: column;
  padding: 3.8rem 2rem 3rem;
  box-sizing: border-box;

  @media(max-width: $xs){
    padding: 2.5rem 2rem 2rem;
  }
 
  p{
    margin-bottom: 1.5rem;
    max-width: 38rem;
    padding: 0 2rem;
  }

  img{
    margin-bottom: 2.5rem;
  }

}

.form-footer {
  font-family: $font_nunito;
  font-weight: normal;
  font-size: 1.3rem;
  line-height: 2rem;
  text-align: left;
  color: $white;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
}

.form-footer {
  a{
    color: $white;
  }
}

.check-group{
  align-self: flex-start;
}

.custom-checkbox {
  display: block;
  margin-bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center; 
  font-size: 1.6rem;
  line-height: 2.6rem;
  text-align: left;
  color: $white;
  margin-bottom: .9rem;
  position: relative;

  input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    cursor: pointer;
    left: .7rem;
    position: absolute;
    top: .2rem;
    opacity: 0;
  }

  label {
    position: relative;
    cursor: pointer;
    font-size: 1.2rem;
    line-height: 2rem;
    text-align: left;
    color: $white;
    font-family: $font_nunito;
    padding-left: 2.5rem;

    &:after{
      content: '';
      display: block;
      position: absolute;
      top: .2rem;
      left: .2rem;
      width: 1rem;
      height: 1rem;
      background-color: $white;
      border-radius: 100%;
      border: .2rem solid $white;
    }

    &:before {
      content:'';
      -webkit-appearance: none;
      background-color: transparent;
      box-shadow: none;
      padding: 0;
      display: inline-block;
      position: absolute;
      vertical-align: middle;
      cursor: pointer;
      margin-right: 0.5rem;
      padding: 0;
      left: 0;
      top: 0;
      width: 1.8rem;
      height: 1.8rem;
      border-radius: 100%;
      background: $brown;
      border: 0;
    }
    

    a{
      color: $white; 
    }
  }

  input:checked + label:after{
    background-color: $brown;
  }

  input:checked + label:before{
    border-color: $white;
  }
  
}

.alert {
  padding: 1rem 2rem;
  display: block;
  text-align: center;
  font-size: 1.2rem;
  line-height: 1.4rem;
  font-family: $font_nunito;
  background-color: rgba(255,255,255,0.56);
  border-radius: 5rem;
  margin-top: 1rem;
  color: $white;
  box-sizing: content-box;
}

.js-fb{
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 9;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url('../imgs/bg-fb.jpg') $blue no-repeat center bottom fixed;
  background-size: cover;

  @media(max-width: $xl){
    background-image: url('../imgs/bg-fb-tab.jpg');
  }

  @media(max-width: $sm){
    background-image: url('../imgs/bg-fb-mob.jpg');
  }
  
  &__bg{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    padding-top: 3.8rem;
    align-items: flex-start;

    @media(max-width: $xs){
      padding-top: 2.5rem;
    }

    img{
      max-width: 14rem;
    }
  }

  &__it {
    width: 100%;
    font-size: 1.6rem;
    line-height: 1.8rem;
    text-align: center;
    color: #27180e;
    max-width: 28.7rem;
    border-radius: 0.7rem;
    background: rgba(250, 196, 22, .92);
    
    border: 0.1rem solid $white;
    min-height: 22rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    padding: 1.2rem;
    box-sizing: border-box;
  
    h3{
      font-weight: bold;
      font-size: 2.4rem;
      line-height: 1.8rem;
      text-align: center;
      color: $white;
      margin-bottom: 1.7rem;
    }
  
    strong{
      font-weight: 700;
    }
  
    &--ko{
      background: rgba(11, 37, 110, 0.86);
      border: .1rem solid $yellow;
      color: $white;
      padding: 1.2rem 2.6rem;
    }
  }
}