html {
  /* 1rem = 10px */
  font-size: 62.5%;
}

body{
  font-size: 1.6rem;
  line-height: 2rem;
  font-family: $font_nunito; 
  font-weight: 400;
  background-color: $blue;
  
  @media(max-width: $xl){
    overflow:hidden;
  }

}

.container{
  margin: 0 auto;
  max-width: 144rem;
  padding: 0 2rem;
}

a, button, input, select{
  &:focus{
    outline: none;
  }
}

.page-content{
  margin-top: 12rem;

  p{
    margin-bottom: 1.6rem;
  }
}