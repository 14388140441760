@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap');

$font_nunito: 'Nunito', sans-serif;

//colors
$black: #000;
$white: #fff;
$yellow : #FAC515;
$blue : #0B256E;
$brown : #452808;

$xxl: 1399.98px;
$xl: 1199.98px;
$lg: 991.98px;
$md: 767.98px;
$sm: 575.98px;
$xs: 376.98px;